body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #224261!important;
}

button {
  border: 0
}

@keyframes fadeInAnimation {
    0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flickerAnimation {
  0%   { opacity:0; }
  25%   { opacity:0.25; }
  50%  { opacity:1; }
  75%  { opacity:0.25; }
  100% { opacity:0; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:0; }
  25%   { opacity:0.25; }
  50%  { opacity:1; }
  75%  { opacity:0.25; }
  100% { opacity:0; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:0; }
  25%   { opacity:0.25; }
  50%  { opacity:1; }
  75%  { opacity:0.25; }
  100% { opacity:0; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:0; }
  25%   { opacity:0.25; }
  50%  { opacity:1; }
  75%  { opacity:0.25; }
  100% { opacity:0; }
}

.loader {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  margin: auto;
  -webkit-animation: flickerAnimation 1s linear;
  -moz-animation: flickerAnimation 1s linear;
  -o-animation: flickerAnimation 1s linear;
  animation: flickerAnimation 1s linear;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mhvh100 {
  min-height: 75vh;
}

.info-hint{
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF8A00;
  font-weight: bold;
  margin-left: 10px;
}

.reaction-container {
  background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
  box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px!important;
  min-height: 400px;
}

.header-container-bar {
  background-color: #e6332a;
  min-height: 60px!important; ;
  border-bottom: 2px solid #00507D;
}

.card-blue-border {
  width: 130px;
  background: #FFFFFF;
  border: 1px solid #224261;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-height: 300px;
}

.card-red-border {
  width: 110px;
  background: white;
  border: 1px solid #E6332A;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-height: 200px;
}

.card-dotted-border {
  background: #FFFFFF;
  border: 0.2px dashed #6B6A6A;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 300px;
  width: 130px;
}

.card-dotted-border-min {
  background: #FFFFFF;
  border: 0.2px dashed #6B6A6A;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 200px;
  min-width: 230px;
}

.bordered-container {
  border: 0.5px dashed #6B6A6A;
  border-top: 0;
  border-bottom: 0;
}

.floating-card {
  /*position: absolute;*/
  /*max-width: 250px;*/
  /*right: -50px;*/
}

.title-result-header {
  position: absolute;
  width: 200px;
  height: 60px;
  background: #FF8A00;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: white;
  font-weight: bold;
  top: -15px
}

.target-product-container {
  width: 150px;
  min-height: 350px;
  background: white;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.reactants-container {
  width: 100%;
  height: 272px;
  background: white;
  box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-top: 10px;
  overflow-x: hidden;
}

.reactants-title-container {
  position: absolute;
  width: 300px;
  height: 50px;
  background: #00507D;
  box-shadow: 5px 3px 4px rgba(0, 0, 0, 0.55);
  border-radius: 24px;
  top: -10px;
  color:white;
  font-weight: bold;
}

.reaction-title-circle {
  position: absolute;
  width: 70px;
  height: 70px;
  top: -20px;
  background: #E5E5E5;
  box-shadow: 0px 0 7px 2px rgba(0, 0, 0, 0.55);
  border-radius: 35px;
}

.reagent-background-color {
  background: #E6332A!important;
}

.button-next-puzzle {
  width: 150px;
  height: 40px;
  background: #FF8A00;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.custom-dropdown-style{
  background-color: white!important;
  color: #464F60!important;
  border-color: #464F60;
  border-radius: 10px!important;
}

.item-chem {
  width:  50px;
  height: 50px;
  margin: 10px;
  animation: fadeInAnimation 1s linear;
}

.target-product {
  animation: fadeInAnimation 3s linear;
}



@media screen and (max-width: 767px)  {

  .loader {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1500px!important;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    margin: auto;
    -webkit-animation: flickerAnimation 1s linear;
    -moz-animation: flickerAnimation 1s linear;
    -o-animation: flickerAnimation 1s linear;
    animation: flickerAnimation 1s linear;
  }

  .info-hint{
    width: 16px!important;
    height: 16px!important;
    border-radius: 8px!important;
    font-size: 8px;
    margin-left: 5px;
  }


  .button-next-puzzle {
    font-size: 14px;
  }

  .reaction-container-min {
    background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
    box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px!important;
    min-height: 150px!important;
  }
  .reactants-container-min-selected {
    background: linear-gradient(180deg, #7A9CC6 0%, #FFFFFF 100%);
    box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px!important;
  }

  .card-blue-border-first {
    min-width: 42px;
    min-height: 70px;
    background: #FFFFFF;
    border: 1px solid #224261;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .card-blue-border {
    min-height: 70px;
    font-size: 12px;
    width:100px!important;
  }

  .card-red-border {
    width: 40px;
    min-width: 41px;
    min-height: 46px;
    font-size: 12px;
  }

  .card-red-border-intermediate {
    background: white;
    border: 1px solid #E6332A;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    min-height: 200px;
    font-size: 12px;
    width:100px!important;
  }

  .card-dotted-border{
    width:120px!important;
    min-height: 105px;
    font-size: 12px;
  }

  .card-dotted-border-min{
    min-width: 42px;
    min-height: 31px;
  }
  .card-dotted-border-intermediate {
    background: #FFFFFF;
    border: 0.2px dashed #6B6A6A;
    box-sizing: border-box;
    border-radius: 5px;
    min-height: 200px;
    min-width: 180px;
    font-size: 12px;
  }

  .target-product-container-min{
    width: 65px;
    height: 100px;
    min-height: 100px;
    background: white;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
  }

  .target-product-container-intermediate {
    width: 300px;
    min-height: 250px;
    background: white;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
  }

  .title-result-header-intermediate {
    position: absolute;
    width: 150px;
    height: 30px;
    background: #FF8A00;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    font-weight: bold;
    top: -15px
  }

  .title-result-header{
    width: 65px;
    height: 24px;
    font-size: 8px;
  }
  .current-element {
    min-width: 340px;
    min-height: 232px;
    background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
    box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px!important;
  }

  .reagent-reactant-container {
    min-width: 100px;
    height: 310px;
    background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
    box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .scrollable-list {
    height: 300px;
    overflow-x: hidden;
  }

  .reactants-title-container {
    position: absolute;
    width: 160px;
    height: 50px;
    background: #00507D;
    box-shadow: 5px 3px 4px rgba(0, 0, 0, 0.55);
    border-radius: 24px;
    top: -20px;
    color:white;
    font-weight: bold;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    font-size: 8px;
    padding-left: 45px;
  }
  .reaction-title-circle {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -25px;
    background: #E5E5E5;
    box-shadow: 0px 0 7px 2px rgba(0, 0, 0, 0.55);
    border-radius: 30px;
  }
  .card-blue-border-selected{
    min-height: 250px;
    background: #FFFFFF;
    border: 1px solid #224261;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 12px;
  }
  .card-red-border-selected {
    background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
    border: 1px solid #E6332A;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    min-height: 150px;
    font-size: 12px;
  }

  .question-mark{
    color: #e6332a;
    font-size: 60px;
    font-weight: bold;
  }
  .custom-dropdown-style{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .title-result-header-min {
    position: relative;
    background: #FF8A00;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    font-weight: bold;
    font-size: 8px;
  }

}
.start-reaction-button {
  width: 100%;
  height: 30px;
  background: #FF8A00;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
}

@media screen and (min-width: 767px)  and (max-width: 1000px)  {
  .start-reaction-button {
    font-size: 8px;
  }

  .info-hint{
    margin-left: 5px;
  }
  .card-blue-border {
    min-height: 210px;
    width: 100px;
    height: 100px!important;
    font-size: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .card-dotted-border {
    width: 100px;
    min-height: 100%;
    height: 110px!important;
    font-size: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container {
    min-width: 100%;
  }

  .card-red-border {
    width: 75px!important;
    height: 110px!important;
    min-height: 100px;
    font-size: 10px;
  }

  .target-product-container {
    width: 100px;
    min-height: 100px;
  }
  .target-product {
    width: 100px;
  }

  .reaction-container {
    min-height: 200px;
  }

  .title-result-header {
    width: 100px;
    height: 30px;
    font-size: 8px;
  }

}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #224261;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF8A00;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e6332a;
}

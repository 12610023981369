

.input-steps {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    width: 100%;
    padding :0 10px;
}

.start-problem-button {
    width: 250px!important;
    height: 50px;
    background: #FF8A00;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.start-problem-button:hover {
    background: rgba(255, 138, 0, 0.8);
}


.start-form-content-container {
    background: linear-gradient(180deg, #E5E5E5 0%, #FFFFFF 100%);
    box-shadow: 8px 7px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px!important;
    height: 200px;
}

